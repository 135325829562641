// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var Highcharts = require('highcharts');
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);

function MentorGraph(element) {
  values = JSON.parse(element.dataset.values)

  Highcharts.chart(element.id, {
    exporting: false,
    
    title: {
        text: ''
    },

    legend: {
      enabled: false,
    },

    yAxis: {
        title: {
            text: ''
        },
        labels: {
          enabled: false,
        },
        min: 0,
        //visible: false,
    },

    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { 
        millisecond: '%b \ %y',
        second: '%b \ %y',
        minute: '%b \ %y',
        hour: '%b \ %y',        
        day: '%b \ %y',
        week: '%b \ %y',
        month: '%b \ %y',
        year: '%Y'
      },
      title: {
          text: ''
      },
  
  },    

    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            },
            pointStart: 2010
        },
    },

    series: values,
    
    tooltip: {
      formatter: function() {
        const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(this.x)
        let formatted_date = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
        return '<b>' + formatted_date + ' - ' + this.point.name + '</b><br/>' + (this.point.feedback ? this.point.feedback : '');
      }
    },  

    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

});
}


document.addEventListener('DOMContentLoaded', function () {
  var mentors = document.getElementsByClassName("mentor-graph") 
  Array.prototype.forEach.call(mentors, function(mentor, index) {
    console.log(mentor)
    MentorGraph(mentors.item(index));
  });
});